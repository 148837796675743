import { useEffect, useRef, useState } from "react";
import { Box, BoxProps, HStack, useBreakpointValue } from "@chakra-ui/react";
import { useMotionValue } from "framer-motion";
import throttle from "lodash.throttle";
import { IWikifolioDetailProps } from "src/api/server/wf-detail.types";
import { MotionBox, Section } from "src/components/base";
import { CONTAINER_PX, CONTAINER_W } from "src/components/layout/container";
import { useNavHeight } from "src/components/layout/hooks/use-nav-height";
import { useRouterCaller } from "src/utils/router/use-router-caller";
import { DATA_ATTR_JUMPMARK_SECTION, EnumJumpMarkSection, THROTTLE_RESIZE_EVENT_MS, THROTTLE_SCROLL_EVENT_MS } from "./consts";
import { useAnchorOffset } from "./hooks/use-anchor-offset";
import { useJumpMarkAnchorRefs } from "./jump-mark-anchor-refs-provider";
import { JumpMarkTab } from "./jump-mark-tab";
import { JUMP_MARKS_BAR_TEST_ID } from "./jump-marks-bar.test-ids";
import { useJumpToSection } from "./use-jump-to-section";
import { getJumpMarkHash, isElementInViewport, scrollTabIntoViewport } from "./utils";

interface IProps extends BoxProps {
    dict: Pick<IWikifolioDetailProps["dict"], "jumpMarks">;
}

export const JumpMarksBar = ({ dict, ...boxProps }: IProps) => {
    const tabBarContainerRef = useRef<HTMLDivElement>(null);
    const tabBarRef = useRef<HTMLDivElement>(null);
    const tabRefs = useRef(new Map<string, HTMLDivElement>()).current;
    const jumpMarkSectionRefs = useRef<HTMLDivElement[]>([]);
    const jumpToSection = useJumpToSection();
    const [dragConstraint, setDragConstraint] = useState(0);
    const { scrollName } = useJumpMarkAnchorRefs();

    const { ANCHOR_OFFSET } = useAnchorOffset();

    // Dev-Note: we're using framer motion to scroll the jumpmark tabs, because else we have problems with scrolling the main area and the tabs at the same time
    const tabScrollX = useMotionValue(0);

    const currentlyScrollingToRef = useRef<EnumJumpMarkSection | null>(null);

    const [activeId, setActiveId] = useState(EnumJumpMarkSection.Overview);
    const anchorOffset = useBreakpointValue(ANCHOR_OFFSET);

    const { isCallerContentApp } = useRouterCaller();
    const { NAV_HEIGHT_VARIATION } = useNavHeight();

    useEffect(() => {
        // let's cache all jumpmark sections so we don't have to query them on every scroll event
        jumpMarkSectionRefs.current = Array.from(document.querySelectorAll<HTMLDivElement>(`*[${DATA_ATTR_JUMPMARK_SECTION}]`).values());

        // on scroll we check which jumpmark section is currently visible and set the active tab accordingly
        const scrollEventListener = throttle(() => {
            Object.values(EnumJumpMarkSection).some((id: EnumJumpMarkSection) => {
                const matchingElements = jumpMarkSectionRefs.current.filter(
                    element => element.dataset[DATA_ATTR_JUMPMARK_SECTION.replace("data-", "")] === id
                );

                for (const element of matchingElements) {
                    // let's look if one of the section elements is in the viewport and if we should make the corresponding tab active

                    if (currentlyScrollingToRef.current === id) {
                        currentlyScrollingToRef.current = null;
                    }

                    if (isElementInViewport(element, anchorOffset)) {
                        if (currentlyScrollingToRef.current === null) {
                            // we only make the tab active if we're currently not scrolling smooth to a section
                            const tabElement = tabRefs.get(id);
                            scrollTabIntoViewport(tabScrollX, tabElement);

                            if (activeId !== id && scrollName === null) {
                                setActiveId(id);
                            }
                        }

                        // we found the first matching section, let's return true and discontinue the upper loop
                        return true;
                    }
                }
                return false;
            });
        }, THROTTLE_SCROLL_EVENT_MS);

        // the resize event listener is used to recalculate the drag constraint with the new tabBar width
        const resizeEventListener = throttle(() => {
            const tabBarContainerWidth = tabBarContainerRef.current?.offsetWidth || 0;
            const tabBarWidth = tabBarRef.current?.scrollWidth || 0;

            let dragConstraint = tabBarContainerWidth - tabBarWidth;
            if (dragConstraint > 0) {
                dragConstraint = 0;
            }

            setDragConstraint(dragConstraint);
        }, THROTTLE_RESIZE_EVENT_MS);

        // we calculate it also on the first render when no resize happened yet
        resizeEventListener();

        window.addEventListener("scroll", scrollEventListener);
        window.addEventListener("resize", resizeEventListener);
        return () => {
            window.removeEventListener("scroll", scrollEventListener);
            window.removeEventListener("resize", resizeEventListener);
        };
    }, [anchorOffset, activeId, tabRefs, tabScrollX, scrollName]);

    const onTabClick = (id: EnumJumpMarkSection) => {
        currentlyScrollingToRef.current = id;
        const tabElement = tabRefs.get(id);
        scrollTabIntoViewport(tabScrollX, tabElement);
        setActiveId(id);
        jumpToSection(id);
    };

    return (
        <Section
            ref={tabBarContainerRef}
            pt={1}
            order={[1, 2]}
            pos="sticky"
            top={isCallerContentApp ? 0 : NAV_HEIGHT_VARIATION}
            bg="white"
            zIndex="docked"
            overflow="hidden"
            sx={{
                "&::-webkit-scrollbar": {
                    display: "none",
                },
                scrollbarWidth: "none",
                msOverflowStyle: "none",
            }}
            onWheel={e => {
                // as it is not a real scrolling container we simulate scrolling with this onWheel event listener
                let newX = tabScrollX.get() - e.deltaX;
                if (newX > 0) {
                    newX = 0;
                } else if (newX < dragConstraint) {
                    newX = dragConstraint;
                }
                tabScrollX.set(newX);
            }}
            data-test-id={JUMP_MARKS_BAR_TEST_ID}
            {...boxProps}
        >
            <Box pos="absolute" bottom={0} width="100%">
                <Box width={CONTAINER_W} px={[0, CONTAINER_PX[1], CONTAINER_PX[2], CONTAINER_PX[3]]} mx="auto">
                    <Box height="1px" bg="gray.200"></Box>
                </Box>
            </Box>
            <Box width={CONTAINER_W} px={[0, CONTAINER_PX[1], CONTAINER_PX[2], CONTAINER_PX[3]]} mx="auto">
                <MotionBox drag="x" dragConstraints={{ left: dragConstraint, right: 0 }} style={{ x: tabScrollX }}>
                    <HStack spacing={0} ref={tabBarRef}>
                        {Object.values(EnumJumpMarkSection).map((id: EnumJumpMarkSection) => (
                            <JumpMarkTab
                                ref={element => (element ? tabRefs.set(id, element) : tabRefs.delete(id))}
                                isActive={activeId === id}
                                key={id}
                                id={id}
                                href={getJumpMarkHash(id)}
                                onClick={e => {
                                    // we prevent default browser scrolling because we want to scroll smooth
                                    e.preventDefault();
                                    onTabClick(id);
                                }}
                                onFocus={() => {
                                    // when the element gets focused we scroll it into view if necessary (e.g when the user navigates with tabulator key)
                                    const tabElement = tabRefs.get(id);
                                    scrollTabIntoViewport(tabScrollX, tabElement);
                                }}
                            >
                                {dict.jumpMarks[id]}
                            </JumpMarkTab>
                        ))}
                    </HStack>
                </MotionBox>
            </Box>
        </Section>
    );
};
